import { Button, Icon, Intent, MenuItem, Slider, Switch, Tag } from "@blueprintjs/core";
import { Select2 } from "@blueprintjs/select";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { formatPercent } from "../../common/utils";
import SimpleSelect from "../common/simple-select";
import { useQueryFormatsPlatform } from "../platform-msg";
import { ALL_PLATFORMS, getPlatformIcon } from "../platforms";

const StyledBudgetProfileForm = styled.div`
    display: grid;
    grid-template-columns: ${({ $showPercent }) => ($showPercent ? "auto 1fr 80px" : "150px auto")};
    grid-gap: 10px 20px;
    padding-right: 10px;
    .label {
        display: flex;
        svg {
            vertical-align: sub;
            font-size: 1.2em;
        }
    }
    .bp4-slider-label {
        border-radius: 3px/10px;
    }
    .platform-message {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }
`;

const FACTOR_NAMES = {
    1: { label: "Min", color: "#FFE39F" },
    2: { label: "Lower", color: "#D2D5A1" },
    3: { label: "Low", color: "#ABC4A2" },
    4: { label: "Below average", color: "#89B2A2" },
    5: { label: "Average", color: "#6B9FA1" },
    6: { label: "Above average", color: "#538BA0" },
    7: { label: "High", color: "#3E769E" },
    8: { label: "Higher", color: "#2D619C" },
    9: { label: "Max", color: "#1F4B99" },
};

const BudgetProfileForm = ({ value, onChange, disabled, showPercent, status }) => {
    const [percents, setPercents] = useState({});
    const { formats } = useQueryFormatsPlatform();
    const handleChangeFactor = (platform, v) => {
        const currentIndex = (value || []).findIndex((p) => p.platform === platform);
        const result = [...value];
        if (currentIndex !== -1) {
            result[currentIndex] = { ...result[currentIndex], budgetAllocationFactor: v };
        }
        onChange(result);
    };

    useEffect(() => {
        const total = (value || []).reduce((acc, p) => (p.active ? acc + p.budgetAllocationFactor : acc), 0);
        if (total > 0) {
            const result = (value || []).reduce(
                (acc, p) => ({ ...acc, [p.platform]: p.budgetAllocationFactor / total }),
                {}
            );
            setPercents(result);
        }
    }, [value, showPercent]);

    const selectedPlatforms = useMemo(
        () => (value || []).map((p) => (p.active ? p.platform : null)).filter((x) => x),
        [value]
    );
    const allPlatforms = useMemo(
        () =>
            ALL_PLATFORMS.map((p) => ({
                label: p,
                value: p,
                icon: getPlatformIcon(p),
            })),
        []
    );
    const handleRemovePlatform = useCallback(
        (platform) => {
            onChange(value.filter((p) => p.platform !== platform));
        },
        [value, onChange]
    );
    const handleAddPlatform = useCallback(
        (platform) => {
            onChange([
                // remove previous entry as we can have active: false element that are not displayed
                ...value.filter((p) => p.platform !== platform),
                {
                    platform,
                    budgetAllocationFactor: 5,
                    active: true,
                    format: platform === "google_ads_search" ? "search" : "image",
                    isDynamic: false,
                },
            ]);
        },
        [value, onChange]
    );

    const handlePlatformAttributeChange = useCallback(
        (valueToUpdate, platform, attribute) => {
            onChange(value.map((p) => (p.platform === platform ? { ...p, [attribute]: valueToUpdate } : p)));
        },
        [value, onChange]
    );
    return (
        <StyledBudgetProfileForm $showPercent={!!showPercent}>
            {(value || []).map(
                (p) =>
                    p.active && (
                        <React.Fragment key={p.platform}>
                            <div className="label">
                                <Tag
                                    fill
                                    large
                                    minimal
                                    intent={disabled ? Intent.NONE : Intent.PRIMARY}
                                    disabled={disabled}
                                    onRemove={disabled ? null : () => handleRemovePlatform(p.platform)}
                                >
                                    <div className="platform-message">
                                        <Icon icon={getPlatformIcon(p.platform)} />
                                        &nbsp;
                                        <span>{p.platform}</span>
                                        <SimpleSelect
                                            disabled={disabled}
                                            options={formats[p.platform]?.[p.isDynamic ? "dynamic" : "static"]}
                                            onSelect={(v) => handlePlatformAttributeChange(v, p.platform, "format")}
                                            selected={p.format}
                                        />
                                        <Switch
                                            checked={p.isDynamic}
                                            label="Dynamic Ad"
                                            disabled={status !== "draft" && status !== "review"}
                                            onChange={(e) =>
                                                handlePlatformAttributeChange(e.target.checked, p.platform, "isDynamic")
                                            }
                                        />
                                    </div>
                                </Tag>
                            </div>
                            <div>
                                <Slider
                                    disabled={disabled}
                                    value={p.budgetAllocationFactor}
                                    onChange={(v) => handleChangeFactor(p.platform, v)}
                                    min={1}
                                    max={9}
                                    stepSize={1}
                                    showTrackFill={false}
                                    intent={Intent.PRIMARY}
                                    labelStepSize={2}
                                    labelRenderer={(v, { isHandleTooltip }) => FACTOR_NAMES[v]?.label}
                                />
                            </div>
                            {showPercent && (
                                <div>
                                    <Tag minimal>{formatPercent(percents[p.platform])}</Tag>
                                </div>
                            )}
                        </React.Fragment>
                    )
            )}
            <div>
                <Select2
                    fill
                    items={allPlatforms}
                    itemRenderer={(item, { handleClick }) => (
                        <MenuItem
                            key={item.value}
                            text={item.label}
                            label={<Icon icon={item.icon} />}
                            icon={selectedPlatforms.includes(item.value) ? "tick" : "none"}
                            onClick={handleClick}
                        />
                    )}
                    onItemSelect={(item) => {
                        if (selectedPlatforms.includes(item.value)) {
                            handleRemovePlatform(item.value);
                        } else {
                            handleAddPlatform(item.value);
                        }
                    }}
                    itemPredicate={(q, item) => {
                        return item.label.toLowerCase().indexOf(q.toLowerCase()) !== -1;
                    }}
                >
                    <Button disabled={disabled} fill intent={Intent.SUCCESS} text="Add platform" icon="plus" />
                </Select2>
            </div>
            <div />
            {showPercent && <div />}
        </StyledBudgetProfileForm>
    );
};

BudgetProfileForm.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            platform: PropTypes.string.isRequired,
            isDynamic: PropTypes.bool.isRequired,
            budgetAllocationFactor: PropTypes.number.isRequired,
        })
    ).isRequired,
    showPercent: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    status: PropTypes.string,
};

BudgetProfileForm.defaultProps = {
    onChange: null,
    disabled: false,
    showPercent: false,
    status: null,
};

export default BudgetProfileForm;
