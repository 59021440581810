import { SiFacebook, SiGoogleads, SiInstagram, SiLinkedin, SiTwitter } from "react-icons/si";

export const PLATFORM_ICON_CMPS = {
    facebook: SiFacebook,
    instagram: SiInstagram,
    twitter: SiTwitter,
    linkedin: SiLinkedin,
    "facebook/instagram": SiFacebook,
    google_ads_search: SiGoogleads,
    google_ads_display: SiGoogleads,
};

export const PLATFORM_NAME_CMPS = {
    google_ads_search: "Search",
    google_ads_display: "Display",
};

const PLATFORM_ICONS = Object.keys(PLATFORM_ICON_CMPS).reduce((acc, platform) => {
    const Cmp = PLATFORM_ICON_CMPS[platform];
    if (Cmp) {
        acc[platform] = (props) => <Cmp {...props} />;
    }
    return acc;
}, {});

export const ALL_PLATFORMS = ["facebook", "instagram", "linkedin", "google_ads_search", "google_ads_display"];

export function getPlatformIcon(platform, props = {}) {
    const iconFunc = PLATFORM_ICONS[platform] || (() => null);
    return iconFunc(props);
}
